import React from 'react'
import Fluid from '../../assets/images/icons/fluid-icon.png'
import Conveyor from '../../assets/images/icons/conveyor.png'
import Slider from './Slider'

import { useRef } from 'react'
import {
	ArrowCircleLeftRounded,
	ArrowCircleRightRounded,
} from '@mui/icons-material'

function Works() {
	const swiperRef = useRef(null)

	return (
		<>
			<div
				id='calisma-alanlarimiz'
				className='w-full flex flex-col justify-center items-center gap-6 mb-10'>
				<div className='max-w-[1280px] w-full flex max-sm:flex-wrap justify-between max-sm:justify-center items-center'>
					<h1 className='text-[32px] max-sm:text-[24px] sm:leading-10 max-sm:leading-6 font-bold'>
						Çalışma Alanlarımız
					</h1>
					<div className='h-12 space-x-6 max-sm:hidden'>
						<button
							onClick={() =>
								swiperRef.current && swiperRef.current.swiper.slidePrev()
							}>
							<ArrowCircleLeftRounded sx={{ fontSize: 40 }} />
						</button>
						<button
							onClick={() =>
								swiperRef.current && swiperRef.current.swiper.slideNext()
							}>
							<ArrowCircleRightRounded sx={{ fontSize: 40 }} />
						</button>
					</div>
				</div>
			</div>
			<Slider swiperRef={swiperRef} />
		</>
	)
}

export default Works
