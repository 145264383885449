import React from 'react';
import Switch from '@mui/material/Switch';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';

function EventCard(props) {
	return (
		<>
			<div
				className={`w-[1280px] max-sm:w-full flex flex-col justify-center items-start p-8  space-y-8 shadow-custom-7 shadow-custom-8 ${props.bgColor}`}
			>
				<div className='flex justify-start items-center max-sm:py-4'>
					<h3 className='text-[32px] max-sm:text-[24px] sm:leading-10 max-sm:leading-6 font-bold'>
						{props.title}
					</h3>
				</div>
				<div className=' w-full'>
					<p className='text-[24px] max-sm:text-[16px] sm:leading-10 max-sm:leading-6 '>
						{props.description}
					</p>
				</div>
				<div className='pt-4 flex justify-start items-center w-full gap-4'></div>
			</div>
		</>
	);
}

export default EventCard;
