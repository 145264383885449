import React from 'react';
import SBasic from '../assets/videos/sorm-basic-video.mp4';
import SBFront from '../assets/images/sb-front.jpg';
import SBScreen from '../assets/images/sb-screen.png';
import { Done } from '@mui/icons-material';
import { Helmet } from 'react-helmet';
function SormBasic() {
	const screenItems = [
		'➔ İstenilen Hacim Değeri',
		'➔ Aktif Hacim Değeri',
		'➔ Sıvı Sıcaklık Değeri',
		'➔ Uyarılar',
		'➔ Kullanım Yardımları',
	];

	const standardSpecifications = [
		'Elektrik sistemi olarak evrensel çalışma aralığı (85-264VAC/120-373VDC)',
		'Sağlam, dayanıklı kompakt çelik* gövde tasarım',
		'Korozyona karşı dayanıklı kaplama boya',
		'1,8 inç Renkli TFT LCD Ekran',
		'Kullanıcı dostu, anlaşılır ara yüzlü yazılım,',
		'Bilgilendirme ve uyarı ekranları,',
		'Otomatik/Manuel kullanım seçeneği,',
		'Farklı hacimsel ölçü birimleri (mililitre, litre, galon veya metreküp)',
		'Aktif sıcaklık ölçümü (Santigrat, Fahrenhayt)',
		'Direkt veya mobil olarak tesisata bağlantı kolaylığı',
		'Tesisat yönüne göre cihaz sıvı giriş yönünün ayarlanabilmesi',
	];

	const optionalSpecifications = [
		'Pompa kontrolü',
		'Harici Start-Stop kontrolü(Sensör, Pedal, Buton…vb.)',
		'Bir sistem veya makine ile birlikte çalışma (Konveyör Bant, Sıvı Dolum Hatları Uygulamalarında Hidrolik ve Pnömatik Sistemleri kontrol edebilme)',
	];
	const technicalSpecifications = [
		'Giriş ve Çıkış Bağlantı: ¾” DN20',
		'Çalışma Gerilimi: 85-264VAC/120-373VDC',
		'Minimum Güç: 5W Maksimum Güç:108W',
		'Maksimum Çalışma Basıncı:16 Bar',
		'Kinematik Viskozite: 38cst',
		'Çalışma Basıncı: (0,5-16 Bar)',
		'Sıvı Debi Oranı (2-60L/dk)',
	];

	return (
		<>
			<Helmet>
				<title>SORM Basic - Sıvı Dolum Makinesi</title>
				<meta
					name='description'
					content='SORM Basic sıvı dolum makinesi, kolay kullanım ve yüksek performans sunar. Su, alkol, deterjan ve madeni yağların istenilen ölçüde otomatik olarak verilmesini sağlar.'
				/>
				<meta
					name='keywords'
					content='SORM Basic, sıvı dolum makinesi, otomatik dolum, endüstriyel dolum makinesi, sıvı kontrol cihazı, alkol dolum, deterjan dolum, madeni yağ dolum'
				/>
			</Helmet>
			<div className='w-full flex flex-col justify-center items-center '>
				<div className='w-full h-screen max-lg:h-full'>
					<video
						className='w-full h-full object-cover'
						src={SBasic}
						autoPlay
						loop
						muted
					></video>
				</div>
				<div className='w-full  flex flex-col justify-center items-center p-8 bg-white text-black'>
					<h1 className='text-[72px] font-bold text-center'>
						SORM Basic ile tanışın.
					</h1>
					<h2 className='text-[32px] text-center' style={{ fontWeight: '400' }}>
						Kolay Kullanım, Yüksek Performans
					</h2>
					<h3 className='text-[28px] text-center' style={{ fontWeight: '400' }}>
						Sıvı Dolum Makinesi
					</h3>
				</div>
				<div className='w-full  flex flex-wrap justify-center items-center p-8 text-black bg-white'>
					<div className='w-[640px] flex flex-col justify-center items-center gap-4 p-3'>
						<p className='text-justify text-[24px]'>
							Üretim proseslerinde veya sıvıların belirli oranlarda kullanılması
							gereken alanlarda, sıvıların istenilen miktarda otomatik olarak
							verilmesini sağlayan sıvı akışkan kontrol cihazlarıdır.
						</p>
						<p className='text-justify text-[24px]'>
							Su, alkol, sıvı deterjan ve madeni yağların, hacimsel olarak
							istenilen ölçüde verilmesini sağlar.
						</p>
					</div>
					<div className='max-w-[640px] flex justify-center items-center'>
						<img src={SBFront} alt='Sorm Basic Sıvı Dolum Makinesi' />
					</div>
				</div>
				<div className='w-full  flex flex-wrap-reverse justify-center items-center text-black bg-white'>
					<div className='max-w-[640px] flex justify-center items-center'>
						<img src={SBScreen} alt='Sorm Basic' />
					</div>
					<div className='w-[640px] flex flex-col justify-center items-start gap-4 p-3'>
						<h6 className='text-[26px] font-bold '>
							Cihaz üzerinde bulunan 1,8 inç Renkli TFT LCD Ekran ile;
						</h6>
						<ul className='w-full max-w-[640px] flex flex-col justify-center items-start text-left gap-2 p-2 text-black'>
							{screenItems.map((item, index) => (
								<li key={index} className='flex items-center gap-4'>
									<p className='text-[24px]'>{item}</p>
								</li>
							))}
						</ul>
						<p className='text-[24px] text-left'>
							Anlık olarak görülebilir, takip edilebilir.
						</p>
					</div>
				</div>
				<div className='w-full  flex flex-wrap justify-center items-center text-black bg-white p-4 pb-12'>
					<div className='w-[640px] flex flex-col justify-center items-start'>
						<h3 className='text-[36px] font-bold '>Standart Özellikler</h3>
						<ul className='w-full max-w-[640px] flex flex-col justify-center items-start text-left gap-2 p-2 text-black'>
							{standardSpecifications.map((spec, index) => (
								<li key={index} className='flex items-center gap-4'>
									<Done />
									<p className=''>{spec}</p>
								</li>
							))}
						</ul>
					</div>
					<div className='w-[640px] flex flex-col justify-center items-start gap-4'>
						<div>
							<h3 className='text-[36px] font-bold '>Opsiyonel Özellikler</h3>
							<ul className='w-full max-w-[640px] flex flex-col justify-center items-start text-left gap-2 p-2 text-black'>
								{optionalSpecifications.map((spec, index) => (
									<li key={index} className='flex items-center gap-4'>
										<Done />
										<p className=''>{spec}</p>
									</li>
								))}
							</ul>
						</div>
						<div>
							<h3 className='text-[36px] font-bold '>Teknik Özellikler</h3>
							<ul className='w-full max-w-[640px] flex flex-col justify-center items-start text-left gap-2 p-2 text-black'>
								{technicalSpecifications.map((spec, index) => (
									<li key={index} className='flex items-center gap-4'>
										<Done />
										<p className=''>{spec}</p>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
				<div className='flex flex-col justify-center items-center pb-12 bg-white w-full text-black'>
					<h5 className='text-center'>
						Faaliyetlerimizle ilgili her türlü soruyu bize sormaktan çekinmeyin.
						Size cevap vermekten mutluluk duyarız.
					</h5>
					<p className='text-center'>+90 532 390 72 78</p>
				</div>
			</div>
		</>
	);
}

export default SormBasic;
