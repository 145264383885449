import React from 'react'
import Data from '../assets/images/data.png'
import TickSquare from '../assets/images/svg/tick-square.svg'
import ArrowSwap from '../assets/images/svg/arrow-swap.svg'
import Hirarchy from '../assets/images/svg/hierarchy-square.svg'

function WhyUs() {
	const data = [
		{
			icon: ArrowSwap,
			title: 'Hızlı Geri Dönüş',
			text: 'Müşterilerimizin istek ve görüşleri doğrultusunda, en etkin çözüm.',
		},
		{
			icon: Hirarchy,
			title: 'Tasarım ve Projelendirme',
			text: 'Tasarımlar ve projelendirmelerden sonra en uygun çözümler belirlenir.',
		},
		{
			icon: TickSquare,
			title: 'Kaliteli Üretim',
			text: 'Dünya standartlarında  mamüller kullanılarak en kaliteli ürünler üretilir.',
		},
	]
	return (
		<>
			<div
				id='hakkimizda'
				className='w-full flex justify-center items-center mt-10'>
				<div className='max-w-[1280px] w-full flex flex-col gap-8 max-sm:p-6'>
					<h1 className='text-4xl font-bold text-left mt-10'>
						İlk seferde en doğru çözüm.
					</h1>
					<div className='flex flex-wrap justify-evenly items-center mb-8'>
						<div>
							<img src={Data} alt='data' />
						</div>
						<div className='w-[500px] text-justify flex flex-col gap-4 max-sm:p-4'>
							<p className='text-xl'>
								RAST MAKİNE, ölçüm , taşıma, tartım ve depolama çözümleri , gıda
								makinelerinde özel kalıp imalatları, firmalara mühendislik ve
								analiz desteği sunan genç ve dinamik bir şirkettir.{' '}
							</p>
							<p className='text-xl'>
								Tarım ve değirmencilik sektörlerinde “Akıllı Fabrika”
								uygulamalarıyla verimliliği artırıcı kit, bulut teknolojisi
								uygulamaları yapmaktadır.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className='bg-[#ffc000] text-black flex justify-center items-center p-8'>
				<div className='max-w-[1280px] w-full flex flex-col gap-8 p-8'>
					<div className='flex justify-evenly flex-wrap items-center gap-8'>
						{data.map((item, index) => (
							<div
								key={index}
								className='flex flex-col text-center gap-8 justify-center items-center '>
								<img src={item.icon} alt='icon' />
								<div className='w-72 h-24 flex-col justify-center items-center gap-6'>
									<h3 className='text-xl font-bold'>{item.title}</h3>
									<p className='text-lg text-justify'>{item.text}</p>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	)
}

export default WhyUs
