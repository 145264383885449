import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './styles.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import SliderCard from '../../Card/SliderCard';

import { ChevronRight } from '@mui/icons-material';

import { breakpoints } from './constants/breakpoints';

export default function Slider({ swiperRef }) {
	const swiper = useSwiper();

	const data = [
		{
			title: 'Sıvı Dolum Sistemleri',
			description:
				'RAST, SORM marka sıvı dolum makineleri ile eldeki görev için özelleştirilmiş yenilikçi dolum çözümleri sunar. Tamamen otomatik sıvı dolum sistemleri, en son teknolojiyi kullanarak yüksek kalite ve verimliliği bir arada sunarken, kullanıcı dostu tasarımıyla da operasyonel kolaylık sağlar.',
			bgColor: 'bg-[#01b0f1]',
		},
		{
			title: 'Taşıma Sistemleri',
			description:
				'RAST, iyileştirilmiş enerji verimliliğini hedefleyen akıllı ve gelişmiş taşıma çözümlerine sistematik olarak odaklanır. Her birproje için geçerli özel koşullara ilişkin ayrıntılı bilgimize dayanarak taşıma çözümleri geliştiriyoruz. Odak noktası, taşınan ürünlerle ilgili kapsamlı bilgi birikimi ile birlikte özenli ve kesin kapasite hesaplamalarında yatmaktadır.',
			bgColor: 'bg-[#006020]',
		},
	];

	return (
		<>
			<Swiper
				ref={swiperRef}
				slidesPerView={1}
				spaceBetween={100}
				loop={true}
				autoplay={{
					delay: 5000,
					disableOnInteraction: true,
				}}
				grabCursor={true}
				// breakpoints={breakpoints}
				modules={[Autoplay, Pagination, Navigation]}
			>
				{data.map((el, index) => (
					<SwiperSlide key={index}>
						<div className='flex justify-center items-center'>
							<SliderCard
								bgColor={el.bgColor}
								title={el.title}
								description={el.description}
							/>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</>
	);
}
