import React from 'react';
import { useLocation } from 'react-router-dom';

function Footer() {
	const location = useLocation();

	const menu_items = [
		{
			name: 'Sorm Master',
			link: 'sorm-master',
			toDiv: false,
		},
		{
			name: 'Sorm Basic',
			link: 'sorm-basic',
			toDiv: false,
		},
		{
			name: 'Çalışma Alanlarımız',
			link: 'calisma-alanlarimiz',
			toDiv: true,
		},
		{
			name: 'Hakkımızda',
			link: 'hakkimizda',
			toDiv: true,
		},
		{
			name: 'Bize Ulaşın',
			link: 'bize-ulasin',
			toDiv: true,
		},
	];

	return (
		<footer>
			<div className='flex flex-col justify-center mt-10'>
				<div className=''>
					<div className='max-sm:w-full flex justify-center items-center  p-2 m-2'>
						<ul className='flex flex-wrap justify-evenly'>
							{menu_items.map((item, index) => (
								<li
									className='h-full flex justify-center items-center'
									key={index}
								>
									<a
										href={
											location.pathname === '/' && item.toDiv
												? `#${item.link}`
												: item.toDiv
												? `/#${item.link}`
												: `/${item.link}`
										}
										className={`h-full flex justify-center items-center font-medium cursor-pointer px-3 py-2 `}
									>
										{item.name}
									</a>
								</li>
							))}
						</ul>
					</div>
				</div>
				<div className='p-2 m-2 flex flex-wrap justify-evenly items-center'>
					<p className='text-center'>
						Rast Makine © {new Date().getFullYear()}
					</p>
					<p className='text-center'>Tüm Hakları Saklıdır.</p>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
