import React from 'react';
import EbubekirVcf from '../assets/Ebu-Bekir.vcf';
import MustafaVcf from '../assets/Mustafa.vcf';
import { Add } from '@mui/icons-material';

function QR() {
	const mk = [
		'Mustafa Koyuncu',
		'+90 532 390 72 78',
		'mustafakoyuncu@rastmakine.com',
		'Horozluhan Mah. Hırka Sk. No: 11 Selçuklu/Konya',
	];

	const ek = [
		'Ebu Bekir Koyuncu',
		'+90 532 389 72 78',
		'ebubekirkoyuncu@rastmakine.com',
		'Horozluhan Mah. Hırka Sk. No: 11 Selçuklu/Konya',
	];

	return (
		<div className='flex flex-col justify-center items-center w-full gap-4 bg-white pt-12'>
			<div className='max-w-[1280px] w-full  p-3'>
				<div className='max-w-[1280px] w-full rounded-xl p-2'>
					<div className='w-full flex justify-center items-center'>
						<h1 className='text-[36px] font-bold text-center text-black'>
							İletişim
						</h1>
					</div>
				</div>
				<div className='max-w-[1280px] w-full rounded-xl p-2'>
					<ul className='w-full flex flex-col justify-center items-center gap-3'>
						{mk.map((item, index) => (
							<li
								key={index}
								className={`text-[20px] text-center text-black ${
									index === 0 && 'font-bold'
								}`}
							>
								{item}
							</li>
						))}
						<a
							href={MustafaVcf}
							className='flex justify-evenly items-center bg-[#000] w-48 text-white px-3 py-2 rounded-lg'
							download='Mustafa.vcf'
						>
							<Add />
							<h1 className='font-bold text-center'>Rehbere Ekle</h1>
						</a>
					</ul>
				</div>
				<hr className='w-4/5 bg-[#000] h-1 mt-2 mb-2 mx-auto' />
				<div className='max-w-[1280px] w-full rounded-xl p-2'>
					<ul className='w-full flex flex-col justify-center items-center gap-3'>
						{ek.map((item, index) => (
							<li
								key={index}
								className={`text-[20px] text-center text-black ${
									index === 0 && 'font-bold'
								}`}
							>
								{item}
							</li>
						))}
						<a
							href={EbubekirVcf}
							className='flex justify-evenly items-center bg-[#000] w-48 text-white px-3 py-2 rounded-lg'
							download='Ebubekir.vcf'
						>
							<Add />
							<h1 className='font-bold text-center'>Rehbere Ekle</h1>
						</a>
					</ul>
				</div>
				<div className='max-w-[1280px] lg:w-full h-96'>
					<iframe
						src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3146.7954502606403!2d32.51673921599892!3d37.93520881068455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d0858dcb1b35dd%3A0xe81913c54efee749!2sRast%20Makine!5e0!3m2!1str!2str!4v1680883364007!5m2!1str!2str'
						style={{
							border: '0px',
							borderRadius: '10px',
							overflow: 'hidden',
							marginTop: '10px',
							width: '100%',
							height: '100%',
						}}
						loading='lazy'
					></iframe>
				</div>
			</div>
		</div>
	);
}

export default QR;
