import React, { useState, useEffect } from 'react';
import SMGif from '../../assets/gif/sm.gif';

function SM360() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Assuming the 3D viewer provides some kind of "loaded" event or callback
    const viewer = document.getElementById('viewer');

    if (viewer) {
      // Replace with actual loading completion logic for the viewer
      const onModelLoaded = () => {
        setIsLoading(false); // Set loading to false when the model is fully loaded
      };

      // Example: If using a library that supports an 'onLoad' event, attach it here
      // viewer.addEventListener('load', onModelLoaded);

      // Placeholder for actual viewer logic, if a custom viewer is used
      // For example, Three.js might have onLoad handlers for textures or models
      // Replace with the real event listener
      setTimeout(() => {
        onModelLoaded(); // Simulate model load complete (replace with actual event)
      }, 0); // Use real event, not timeout

      // Cleanup listener if necessary
      return () => {
        // viewer.removeEventListener('load', onModelLoaded);
      };
    }
  }, []);

  return (
    <section id="threesixty" className="container">
      <div id="threesixty-main">
        <p className='text-lg text-center'>SORM Master'ı keşfetmek için aşağıdaki modeli dokunarak kaydırın, sürükleyin ve yakınlaştırın.</p>

        {/* Display loading gif while model is loading */}
        {isLoading ? (
          <div className='flex justify-center items-center'>
            <img src={SMGif} alt="Sorm Master loading" className='w-32' />
          </div>
        ) : null}

        {/* Display the viewer once loading is done */}
        <div id="viewer" className='w-[800px]' style={{ display: isLoading ? 'none' : 'block' }}>
          {/* 3D model or viewer content goes here */}
        </div>
      </div>
    </section>
  );
}

export default SM360;
