import React, { useState, useEffect, useRef } from 'react'
import Tick from '../assets/images/svg/tickcircle.svg'
import Button from '@mui/material/Button'
import { ArrowRightAlt } from '@mui/icons-material'
import SormBasicFeatured from '../assets/images/sorm-basic-featured.png'
import Slide from '@mui/material/Slide'

function SBFeatured() {
	const items = [
		'Verimliliğinizi arttırın, maliyetlerinizi düşürün',
		'Kompakt tasarımı ile alan tasarrufu',
		'Bakım Kolaylığı, kolayca sökülüp temizlenebilen parçalar',
		'Hızlı ve net ölçülerde dolum',
		'AISI 304 Paslanmaz Çelik',
		'Gıda, yağ veya kimyasallara uygun',
	]

	const [inView, setInView] = useState(false)
	const containerRef = useRef(null)

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting) {
					setInView(true)
					observer.disconnect() // Stop observing after the animation is triggered
				}
			},
			{ threshold: 0.5 }
		)

		if (containerRef.current) {
			observer.observe(containerRef.current)
		}

		return () => {
			if (observer && observer.disconnect) {
				observer.disconnect()
			}
		}
	}, [])

	return (
		<div className='w-full flex justify-center items-center' ref={containerRef}>
			<div className='max-w-[1280px] flex-wrap w-full flex justify-between max-lg:justify-center items-center sm:p-24 max-sm:p-8 gap-8'>
				<div>
					<Slide
						direction={'right'}
						in={inView}
						timeout={2000}
						mountOnEnter
						unmountOnExit>
						<img src={SormBasicFeatured} alt='Sorm Basic' />
					</Slide>
				</div>
				<div className='flex flex-col gap-8 justify-end items-end max-lg:justify-center max-lg:items-center '>
					<h1 className='text-[72px] font-bold max-sm:text-center'>
						SORM Basic
					</h1>
					<ul className='flex flex-col items-end gap-4'>
						{items.map((item, index) => (
							<li key={index} className='flex text-right items-end gap-4'>
								<span>{item}</span>
								<img src={Tick} alt='Tick' />
							</li>
						))}
					</ul>
					<Button
						variant='contained'
						color='turquoise'
						endIcon={<ArrowRightAlt />}
						size='large'
						style={{ width: '200px' }}
						href='/sorm-basic'>
						Detaylar
					</Button>
				</div>
			</div>
		</div>
	)
}

export default SBFeatured
