import React from 'react';
import { useEffect, useRef, useState } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import {
	Bolt,
	CreateNewFolder,
	Science,
	InvertColors,
	Done,
	HourglassBottom,
} from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Slide, Fade } from '@mui/material';
import HizliDolumSM from '../../assets/images/sm-hizli-dolum.png';
import NetDolumSM from '../../assets/images/sm-net-dolum.png';
import SM1 from '../../assets/images/sm-w-1.jpeg';
import SM2 from '../../assets/images/sm-w-2.jpeg';
import SM3 from '../../assets/images/sm-w-3.jpeg';
import SM4 from '../../assets/images/sm-w-4.jpeg';
import SM5 from '../../assets/images/sm-w-5.jpeg';
import SM6 from '../../assets/images/sm-w-6.jpeg';
import SM360 from '../../components/SM360';
import { Helmet } from 'react-helmet';

function SormMaster() {
	const theme = useTheme();

	const data = [
		{
			icon: Bolt,
			text: 'Hızlı ve net ölçülerde dolum',
			iconColor: '#fff',
			dotColor: '#01b0f1',
		},
		{
			icon: HourglassBottom,
			text: 'Zamanlı ve flowmetreli dolum modu',
			iconColor: '#fff',
			dotColor: '#01b0f1',
		},
		{
			icon: CreateNewFolder,
			text: '10 farklı reçete kaydedebilme, değiştirme ve silme özelliği',
			iconColor: '#fff',
			dotColor: '#01b0f1',
		},
		{
			icon: Science,
			text: 'Gıda, yağ veya kimyasallara uygun',
			iconColor: '#fff',
			dotColor: '#01b0f1',
		},
		{
			icon: InvertColors,
			text: 'AISI 304/316 Paslanmaz Çelik',
			iconColor: '#fff',
			dotColor: '#01b0f1',
		},
	];

	const specifications = [
		{ text: 'Uzunluk x Genişlik x Yükseklik: 500 x 500 x 1100 mm' },
		{ text: 'Maksimum debi: 45 L/dk ~ 3m³/saat' },
		{ text: '220VAC/380VAC Seçenekleri' },
		{ text: 'Sıvıya temas eden tüm yüzeyler AISI 304 paslanmaz' },
		{ text: 'Gıda, yağ veya kimyasallara uygun' },
		{ text: 'Paslanmaz kamlok bağlantı' },
		{ text: 'Kendinden emiş sistemi' },
		{ text: 'Nozul boyu değiştirilebilen özel tasarım dolum tabancası' },
		{ text: 'Renkli TFT LCD ekran' },
		{ text: 'Sesli ve ışıklı hata bildirimleri' },
	];

	const [currentImage, setCurrentImage] = useState(0);
	const [currentImage2, setCurrentImage2] = useState(0);
	const images = [SM1, SM2, SM3, SM4, SM5, SM6];
	const images2 = [HizliDolumSM, NetDolumSM];

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentImage((prevImage) => (prevImage + 1) % images.length);
		}, 3000); // Change image every 3 seconds
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentImage2((prevImage) => (prevImage + 1) % images2.length);
		}, 5000); // Change image every 3 seconds
		return () => clearInterval(interval);
	}, []);

	return (
		<>
			<Helmet>
				<title>SORM Master - Sıvı Dolum Makinesi</title>
				<meta
					name='description'
					content='SORM Master sıvı dolum makinesi, kolay kullanım ve yüksek performans sunar. Su, alkol, deterjan ve madeni yağların istenilen ölçüde otomatik olarak verilmesini sağlar.'
				/>
				<meta
					name='keywords'
					content='SORM Master, sıvı dolum makinesi, otomatik dolum, endüstriyel dolum makinesi, sıvı kontrol cihazı, alkol dolum, deterjan dolum, madeni yağ dolum'
				/>
			</Helmet>
			<div className='w-full flex flex-col justify-center items-center bg-[#f7f7f7] text-black'>
				<div className='flex flex-col justify-center items-center w-full h-screen max-lg:h-full'>
					<img
						src={images2[currentImage2]}
						alt='Sorm Master'
						className='w-full h-full object-cover'
					/>
				</div>
				<div className='max-w-[1280px] max-sm:hidden'>
					<Timeline
						position='alternate'
						sx={{
							marginTop: '2rem',
							marginBottom: '2rem',
						}}
					>
						{data.map((el, index) => (
							<Slide
								key={index}
								direction={index % 2 === 0 ? 'right' : 'left'}
								in={true}
								timeout={index * 600}
								mountOnEnter
								unmountOnExit
							>
								<TimelineItem key={index}>
									<TimelineOppositeContent
										sx={{ m: 'auto 0' }}
										align='right'
										variant='body2'
										color='text.secondary'
									>
										<Typography
											variant='h6'
											style={{
												color: 'black',
												fontWeight: 'bold',
												fontSize: '1.5rem',
											}}
										>
											{el.text}
										</Typography>
									</TimelineOppositeContent>

									<TimelineSeparator>
										<TimelineDot sx={{ backgroundColor: el.dotColor }}>
											<el.icon sx={{ color: el.iconColor, fontSize: '2rem' }} />
										</TimelineDot>
										<Fade
											key={index}
											in={true}
											timeout={10000}
											mountOnEnter
											unmountOnExit
										>
											<TimelineConnector
												style={{
													width: '5px',
													backgroundColor: el.dotColor,
													borderRadius: '5px',
												}}
											/>
										</Fade>
									</TimelineSeparator>
									<TimelineContent></TimelineContent>
								</TimelineItem>
							</Slide>
						))}
					</Timeline>
				</div>
				<div className='sm:hidden'>
					<ul className='w-full max-w-[640px] flex flex-col justify-center items-start text-left gap-2 p-2 my-4'>
						{data.map((spec, index) => (
							<Fade in={true} timeout={index * 1500} mountOnEnter unmountOnExit>
								<li key={index} className='flex items-center gap-4'>
									<spec.icon />
									<p className=' text-black'>{spec.text}</p>
								</li>
							</Fade>
						))}
					</ul>
				</div>
				<div className='max-w-[1280px] w-full flex flex-col justify-center items-center'>
					<Fade
						direction='down'
						in={true}
						timeout={5000}
						mountOnEnter
						unmountOnExit
					>
						<Typography
							fontWeight={700}
							variant='h1'
							sx={{
								fontSize: {
									xs: '2rem', // small devices
									sm: '2.5rem', // small to medium devices
									md: '3rem', // medium to large devices
									lg: '3.5rem', // large devices
									xl: '4rem', // extra large devices
								},
							}}
							color='initial'
						>
							Sorm Master
						</Typography>
					</Fade>
					<div className='mt-6'>
						<SM360 />
					</div>
					<div className='flex flex-wrap justify-center items-center w-full max-w:[1280px]  mt-4'>
						<div className='w-[500px] p-3 m-3 text-left'>
							<h2 className='text-[36px] max-sm:text-[24px] font-bold text-left text-black'>
								Neden{' '}
								<span className='bg-[#01b0f1] text-white'>SORM Master</span>
							</h2>
							<p className='text-justify '>
								SORM Master, özellikle hızlı ve verimli dolum işlemleri için
								tasarlanmıştır. Nozul boyu ayarlanabilen özel dolum tabancası,
								farklı sıvı türlerine ve ambalaj boyutlarına uyum sağlar.
								Kendinden emişli sistemi sayesinde harici bir pompaya ihtiyaç
								duymadan hızlı ve kesintisiz dolum yapar. Dakikada 45 litreye
								kadar dolum kapasitesi sunarak büyük hacimli sıvı dolumlarında
								zaman kazandırır. SORM Master, yüksek performansı ve kullanım
								kolaylığı ile dolum süreçlerinizi en üst düzeye çıkarmak için
								mükemmel bir çözümdür.
							</p>
						</div>
						<div className='relative'>
							<div
								className='bg-[#01b0f1] w-[600px] h-[337px] absolute -bottom-5 -right-5 max-sm:hidden'
								style={{ zIndex: 1 }}
							></div>
							<iframe
								width={400 * 1.5}
								height={225 * 1.5}
								style={{ zIndex: 2, position: 'relative' }}
								className={`max-sm:h-[213px] max-sm:w-[360px] max-sm:object-cover max-sm:p-3`}
								src='https://www.youtube.com/embed/Dbx_G4Z3ETk?autoplay=1&mute=1&loop=1&playlist=Dbx_G4Z3ETk&controls=0&modestbranding=1&showinfo=0&rel=0&iv_load_policy=3&enablejsapi=1&origin=https://www.rastmakine.com'
								title='Sıvı Dolum Makinesi - SORM MASTER Tabancalı Dolum Makinesi / 5 Litre Dolum'
								frameborder='0'
								allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
								referrerpolicy='strict-origin-when-cross-origin'
							></iframe>
						</div>
					</div>

					<div className='flex flex-col  justify-center items-center w-full max-w:[1280px] bg-[#f7f7f7] mt-8 pt-4'>
						<h1 className='text-[36px] font-bold text-center text-black'>
							Başlıca Özellikler
						</h1>
						<div className='flex flex-wrap justify-center items-center w-full mt-4 ]'>
							<div className='flex '>
								<img
									src={images[currentImage]}
									alt='Sorm Master'
									loading='lazy'
									className='max-w-[640px] max-sm:w-full'
								/>
							</div>
							<ul className='w-full max-w-[640px] flex flex-col justify-center items-start text-left gap-2 p-4 text-black'>
								{specifications.map((spec, index) => (
									<li key={index} className='flex items-center gap-4'>
										<Done />
										<p className=' '>{spec.text}</p>
									</li>
								))}
							</ul>
						</div>
					</div>

					<div className='flex flex-col justify-center items-center mt-8 p-4'>
						<h5 className='text-center'>
							Faaliyetlerimizle ilgili her türlü soruyu bize sormaktan
							çekinmeyin. Size cevap vermekten mutluluk duyarız.
						</h5>
						<p className='text-center'>+90 532 390 72 78</p>
					</div>
				</div>
			</div>
		</>
	);
}

export default SormMaster;
