import React from 'react';
import SMFeatured from '../components/SMFeatured';
import SBFeatured from '../components/SBFeatured';
import Works from '../components/Works/Works';
import WhyUs from '../components/WhyUs';
import ContactUs from '../components/ContactUs/ContactUs';

function Home() {
	return (
		<>
			<SMFeatured />
			<SBFeatured />
			<Works />
			<WhyUs />
			<ContactUs />
		</>
	);
}

export default Home;
